"use client"

import {
  createContext,
  useContext,
  useMemo,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from "react"

import type { TLinkedProductsCard } from "~/components/ui/LinkedProductsCard/_data/serializer"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"

type ProductsBundleContextProps = {
  setActiveVariants: Dispatch<SetStateAction<TVariant[]>> | null
  activeVariants: TVariant[]
  setActiveProducts: Dispatch<SetStateAction<TLinkedProductsCard[]>> | null
  activeProducts: TLinkedProductsCard[]
}

type ProductsBundleProviderProps = PropsWithChildren<{}>

const ProductsBundleContext = createContext<ProductsBundleContextProps>({
  setActiveVariants: null,
  setActiveProducts: null,
  activeVariants: [],
  activeProducts: [],
})

export function useProductsBundleContext() {
  return useContext(ProductsBundleContext)
}

export function ProductsBundleProvider({ children }: ProductsBundleProviderProps) {
  const [activeVariants, setActiveVariants] = useState<TVariant[]>([])
  const [activeProducts, setActiveProducts] = useState<TLinkedProductsCard[]>([])
  const storeContextValue: ProductsBundleContextProps = useMemo(() => {
    return {
      activeVariants,
      activeProducts,
      setActiveProducts,
      setActiveVariants,
    }
  }, [activeVariants, setActiveVariants, activeProducts, setActiveProducts])

  return <ProductsBundleContext.Provider value={storeContextValue}>{children}</ProductsBundleContext.Provider>
}
