"use client"

import { clsx } from "clsx"

import Slider, { useSliderState, type SliderProps } from "@unlikelystudio/react-slider"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import type { TCollection, TCollectionItem } from "~/components/slices/Collection"
import ClientSliderNavigation from "~/components/slices/Collection/ClientSliderNavigation"

import { sprinkles } from "~/styles/sprinkles.css"

export type ClientSliderProps = PropsWithClassName<
  {
    navigationClassName?: string
    children: SliderProps["children"]
    items: Nullish<Pick<TCollectionItem, "theme">[]>
  } & Omit<TCollection, "items" | "itemsMobile">
>

function ClientSlider({ className, navigationClassName, children, items }: ClientSliderProps) {
  const [{ prevSlide, nextSlide, enableDrag, slideIndex }, setSliderState] = useSliderState()

  if (!items) return null

  const currentTheme = items[slideIndex]?.theme ?? "black"

  return (
    <>
      <Slider
        className={clsx(className, sprinkles({ height: "100%" }))}
        containerClassName={sprinkles({ height: "100%" })}
        setSliderState={setSliderState}
        snap
        infinite={items.length > 1}
      >
        {children}
      </Slider>
      {enableDrag && items.length > 1 && (
        <ClientSliderNavigation
          className={clsx(navigationClassName)}
          prevSlide={prevSlide}
          nextSlide={nextSlide}
          theme={currentTheme}
        />
      )}
    </>
  )
}

export default ClientSlider
