"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"
import { clsx } from "clsx"

import type { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export type ClientSliderNavigationProps = PropsWithClassName<
  Pick<ReturnType<typeof useSliderState>[0], "prevSlide" | "nextSlide"> & RecipeVariants<typeof css.button>
>

function ClientSliderNavigation({ className, theme, prevSlide, nextSlide }: ClientSliderNavigationProps) {
  const t = useTranslate()

  return (
    <div className={clsx(css.ClientSliderNavigation, className)}>
      <button className={clsx(css.button({ theme: theme }))} aria-label={t("aria_arrow_prev")} onClick={prevSlide}>
        <Icon name="Arrow" direction="left" theme={theme === "white" ? "grey-delta/60" : "white"} width={12} />
      </button>
      <button className={clsx(css.button({ theme: theme }))} aria-label={t("aria_arrow_next")} onClick={nextSlide}>
        <Icon name="Arrow" direction="right" theme={theme === "white" ? "grey-delta/60" : "white"} width={12} />
      </button>
    </div>
  )
}

export default ClientSliderNavigation
