"use client"

import type { PropsWithChildren } from "react"
import clsx from "clsx"

import { Link } from "~/components/ui/Link"
import type { TMediaShopTheLookItem } from "~/components/ui/MediaShopTheLookItem"
import ShopTheLookPanel from "~/components/ui/Panels/ShopTheLook"
import * as css from "~/components/slices/Diptich4/styles.css"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

type Diptich4LinkProps = PropsWithChildren<{
  withShopTheLookCta: TMediaShopTheLookItem["withShopTheLookCta"]
  handles: TMediaShopTheLookItem["shopTheLookHandles"]
  link: TMediaShopTheLookItem["link"]
}>
export default function MediaShopTheLookLinkClient({ withShopTheLookCta, handles, link, children }: Diptich4LinkProps) {
  const { add } = usePanel()

  return (
    <Link
      onClick={() => withShopTheLookCta && handles && add(<ShopTheLookPanel handles={handles} />)}
      className={clsx(css.ImageCard, sprinkles({ position: "relative", cursor: "pointer" }))}
      {...link}
      href={withShopTheLookCta ? "" : link?.href}
    >
      {children}
    </Link>
  )
}
