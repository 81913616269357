"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { isRTFilled } from "~/lib/storyblok/utils/check-empty-string"
import HtmlRichText from "~/components/ui/HtmlRichText"
import { Image, type ImageProps } from "~/components/ui/Image"
import LoginPanel from "~/components/ui/Panels/Login"
import RichText from "~/components/ui/RichText"
import SquareCta from "~/components/ui/SquareCta"
import type { SliceWithProps } from "~/components/slices/SliceManager"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const IMAGE_SIZES = [{ ratio: 1 }]

export type THeroBanner = SliceWithProps<{
  title?: Nullish<SbRichtext | string>
  text?: Nullish<SbRichtext | string>
  isFullWidth?: boolean
  image?: Nullish<ImageProps>
  textSize?: "small" | "medium" | "large"
  ctaLogin?: boolean
}>

export type HeroBannerProps = { isCollection?: boolean } & THeroBanner &
  RecipeVariants<typeof css.themeRecipe> &
  RecipeVariants<typeof css.headlineRecipe>

function HeroBanner({
  className,
  title,
  text,
  image,
  ctaLogin,
  isFullWidth = true,
  isFirstSlice = false,
  theme,
  isCollection = false,
  textSize,
  font,
}: HeroBannerProps) {
  const hasImage = Boolean(image)
  const titleIsString = typeof title === "string"
  const textIsString = typeof text === "string"
  const hasTitle = titleIsString ? title?.length > 0 : isRTFilled(title)
  const hasText = textIsString ? text?.length > 0 : isRTFilled(text)

  const t = useTranslate()
  const { add } = usePanel()

  if (!hasImage && !hasTitle && !hasText) return null
  return (
    <div
      data-comp="Slices/HeroBanner"
      className={clsx(className, sprinkles({ position: "relative" }), css.HeroBanner({ isFullWidth, isCollection }))}
    >
      {image && (
        <Image
          className={clsx(css.image)}
          imageClassName={clsx(css.img)}
          {...image}
          ratio={{ mobile: "375_404", tablet: "1440_580" }}
          sizesFromBreakpoints={IMAGE_SIZES}
          priority={isFirstSlice}
        />
      )}
      {(hasTitle || hasText) && (
        <div className={clsx(css.container, hasImage ? sprinkles({ color: "white" }) : sprinkles({ color: "black" }))}>
          {titleIsString ? (
            <h1
              className={clsx(
                css.headline,
                isCollection ? css.headlineCollection({ font }) : css.headlineRecipe({ font, size: textSize }),
                css.themeRecipe({ theme: hasImage ? theme : "black" })
              )}
            >
              {title}
            </h1>
          ) : (
            <RichText
              disableStyles
              className={clsx(
                css.headline,
                isCollection ? css.headlineCollection({ font }) : css.headlineRecipe({ font, size: textSize }),
                css.themeRecipe({ theme: hasImage ? theme : "black" })
              )}
              render={title}
            />
          )}
          {text &&
            (textIsString ? (
              <HtmlRichText className={clsx(css.description, css.themeRecipe({ theme: hasImage ? theme : "black" }))}>
                {text}
              </HtmlRichText>
            ) : (
              <RichText
                disableStyles
                className={clsx(css.description, css.themeRecipe({ theme: hasImage ? theme : "black" }))}
                render={text}
              />
            ))}
          {ctaLogin && (
            <SquareCta theme="backgroundBlack" onClick={() => add(<LoginPanel />)} aria-label={t("account_log_in")}>
              {t("account_log_in")}
            </SquareCta>
          )}
        </div>
      )}
    </div>
  )
}

export default HeroBanner
