import type { RecipeVariants } from "@vanilla-extract/recipes"
import { clsx } from "clsx"

import type { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"

import * as css from "./styles.css"

export type ClientSliderNavigationProps = PropsWithClassName<
  Pick<ReturnType<typeof useSliderState>[0], "maxSlideIndex" | "slideIndex" | "setSlideIndex" | "enableDrag">
> &
  RecipeVariants<typeof css.button>

function ClientSliderNavigation({
  className,
  maxSlideIndex,
  slideIndex,
  setSlideIndex,
  enableDrag,
  theme = "black",
}: ClientSliderNavigationProps) {
  if (!enableDrag) return null

  return (
    <div className={clsx(css.ClientSliderNavigation, className)}>
      {Array(maxSlideIndex + 1)
        .fill(null)
        .map((_, index) => (
          <button
            key={index}
            aria-label={`index ${index}`}
            className={clsx(
              css.button({
                activeBlack: theme === "black" && index === slideIndex,
                activeWhite: theme === "white" && index === slideIndex,
                theme,
              })
            )}
            onClick={() => setSlideIndex(index)}
          />
        ))}
    </div>
  )
}

export default ClientSliderNavigation
