"use client"

import { useMemo } from "react"
import clsx from "clsx"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import { formatCheckoutItems } from "~/lib/shopify/utils/format-checkout-items"
import { AddToCartCta } from "~/components/ui/AddToCartCta"
import type { TLinkedProductsCard } from "~/components/ui/LinkedProductsCard/_data/serializer"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import { useProductsBundleContext } from "~/components/slices/ProductsBundle/providers/ProductsBundleProvider"
import { getProductTrackingDataWithCollections } from "~/providers/GTMTrackingProvider/utils/get-product-tracking-data-with-collections"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useCollectionTree } from "~/managers/CollectionsTreeManager/hooks"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

type BundleAddToCartProps = PropsWithClassName

function variantBelongsToPillowcase(variant: TVariant | null, pillowCaseProduct: Partial<TLinkedProductsCard>) {
  if (!variant) return false
  const belongsToPillowcase = pillowCaseProduct?.variants?.find(
    (pillowcaseVariant) => pillowcaseVariant?.id === variant?.id
  )
  return belongsToPillowcase
}

function BundleAddToCart({ className }: BundleAddToCartProps) {
  const { activeVariants, activeProducts: products } = useProductsBundleContext()

  const [collectionTree] = useCollectionTree()
  const { data: customer } = useGetCustomer()
  const pillowcaseProduct = products.find((product) => product.isPillowCase)
  const processedTrackingData = products
    .map((product, key) => {
      if (!product || !product?.trackingData) return
      const variant = activeVariants?.[key] ?? null
      if (!variant) return
      const price = variant?.vpPrice?.price?.amount ?? variant?.price?.amount ?? 0

      return {
        ...getProductTrackingDataWithCollections(
          product?.trackingData,
          product?.collectionsHandles ?? [],
          collectionTree
        ),
        ...(activeVariants?.[key]
          ? {
              ...variantToTrackingData(activeVariants?.[key] ?? null),
              ...(customer && activeVariants?.[key]?.vpPrice?.price ? { price } : {}),
            }
          : {}),
      }
    })
    .filter(Boolean)

  const checkoutItems = useMemo(() => {
    const variantsToFormat = activeVariants
      .map((variant, key) => {
        return variant && variant.id && processedTrackingData?.[key]
          ? {
              variant,
              quantity:
                pillowcaseProduct && variantBelongsToPillowcase(activeVariants?.[key] ?? null, pillowcaseProduct)
                  ? 2
                  : 1,
              trackingData: processedTrackingData?.[key] ?? null,
            }
          : null
      })
      .filter(Boolean)

    return formatCheckoutItems(variantsToFormat)
  }, [JSON.stringify(activeVariants)])

  const isOutOfStock = activeVariants?.some((variant) => !variant?.availableForSale)
  return (
    <div className={clsx(css.BundleAddToCart, className)}>
      <AddToCartCta
        className={clsx(
          css.cta,
          text({ design: "neue-11-13" }),
          sprinkles({
            pY: { mobile: 10, tablet: 12 },
          })
        )}
        isOutOfStock={isOutOfStock}
        withPrice
        activeVariants={activeVariants}
        checkoutItems={checkoutItems}
        overrideTextPresets
      />
    </div>
  )
}

export { BundleAddToCart }
