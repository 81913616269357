"use client"

import { useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { clsx } from "clsx"
import { AnimatePresence, m } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { useWindowSize } from "usehooks-ts"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import { useMathFitStyle } from "~/hooks/useMathFitStyle"
import { FillImage } from "~/components/ui/FillImage"
import type { ImageProps } from "~/components/ui/Image"
import { type VideoPlayerProps } from "~/components/ui/VideoPlayer"
import type { getVimeoVideoThumbnail } from "~/components/abstracts/VideoPlayer/utils/get-vimeo-data"

import { sprinkles } from "~/styles/sprinkles.css"

const VideoPlayer = dynamic(() => import("~/components/ui/VideoPlayer"), { ssr: false })

export type VimeoVideoPlayerProps = PropsWithClassName<
  VideoPlayerProps &
    Pick<ImageProps, "sizesFromBreakpoints" | "priority"> & {
      thumbnail: InferReturn<typeof getVimeoVideoThumbnail>
    }
>

function ClientVimeoVideoPlayer({
  className,
  thumbnail,
  sizesFromBreakpoints,
  priority,
  fill = false,
  ...props
}: VimeoVideoPlayerProps) {
  const [onReady, setOnReady] = useState(false)
  const { width } = useWindowSize()

  const aspectRatio = props.ratio && `${props.ratio}`
  const { parentRef, targetRef, processed } = useMathFitStyle({ type: "cover" }, [onReady, width])
  const [ref, inView] = useInView()
  const [shouldLoad, setShouldLoad] = useState<boolean>(false)

  useEffect(() => {
    if (inView) setShouldLoad(true)
  }, [inView])

  return (
    <div
      className={clsx(className, sprinkles({ position: "relative", overflow: "hidden" }))}
      style={aspectRatio && !fill ? { aspectRatio } : undefined}
      ref={parentRef}
    >
      <div ref={ref} />
      <div
        ref={targetRef}
        style={fill ? processed : undefined}
        className={clsx(
          fill
            ? sprinkles({
                position: "absolute",
                top: "0",
                left: "0",
              })
            : sprinkles({
                width: "100%",
              })
        )}
      >
        {shouldLoad && (
          <VideoPlayer
            className={sprinkles({ width: "100%", height: "100%" })}
            playerClassName={fill ? sprinkles({ height: "100%", width: "100%" }) : ""}
            playing={inView}
            muted={true}
            onReady={() => setOnReady(true)}
            fill={fill}
            {...props}
          />
        )}
      </div>
      <AnimatePresence>
        {thumbnail && !onReady && (
          <m.div
            initial={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { duration: 1, ease: "ease" },
            }}
          >
            <FillImage
              className={sprinkles({ position: "absolute", inset: 0 })}
              {...thumbnail}
              sizesFromBreakpoints={sizesFromBreakpoints}
              asPlaceholder
              priority={priority}
            />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ClientVimeoVideoPlayer
