"use client"

import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { Link } from "~/components/ui/Link"
import ShopTheLookPanel from "~/components/ui/Panels/ShopTheLook"
import type { THeroLookbook } from "~/components/slices/HeroLookbook"
import { usePanel } from "~/managers/PanelManager"

export type HeroLookbookProps = PropsWithClassName<Pick<THeroLookbook, "link" | "handles">>

function ClientHeroLookbook({ className, children, link, handles }: HeroLookbookProps) {
  const { add } = usePanel()

  const handleOnClick = () => {
    handles && handles.length > 1 && add(<ShopTheLookPanel handles={handles} />)
  }

  return (
    <Link className={clsx(className)} {...link} onClick={handleOnClick}>
      {children}
    </Link>
  )
}

export default ClientHeroLookbook
