"use client"

import { useRef, useState } from "react"
import Script from "next/script"
import { env } from "~/env"
import clsx from "clsx"
import { useInterval } from "usehooks-ts"

import type { ExplicitAny } from "~/@types/generics"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import useLocale from "~/hooks/useLocale"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

declare global {
  interface Window {
    ShipupJS: ExplicitAny
  }
}

export type TShipUp = {
  locale: string
}
export type ShipUpProps = SliceWithProps<TShipUp>

function ShipUp({ className, isFirstSlice }: ShipUpProps) {
  const containerRef = useRef(null)
  const [isRunning, toggleIsRunning] = useState(true)
  const locale = useLocale()

  useInterval(
    () => {
      if (window?.ShipupJS) {
        toggleIsRunning(false)
        const shipup = new window.ShipupJS.default(env.NEXT_PUBLIC_SHIPUP_APP_KEY)
        shipup.render(containerRef.current, {
          language: getLang(locale),
          searchEnabled: false,
        })
      }
    },
    isRunning ? 100 : null
  )

  return (
    <>
      <Script type="text/javascript" src="https://cdn.shipup.co/latest_v2/shipup-js.js" />
      <link rel="stylesheet" href="https://cdn.shipup.co/latest_v2/shipup.css"></link>
      <div className={clsx(css.wrapper)}>
        <div ref={containerRef} className={clsx(className, css.ShipUp({ isFirstSlice }))} />
      </div>
    </>
  )
}

export default ShipUp
