"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import Slider, { useSliderState, type SliderProps } from "@unlikelystudio/react-slider"

import ArrowButton from "~/components/ui/SliderNavigation/components/ArrowButton"
import ClientSliderNavigation from "~/components/slices/Diptich/ClientSliderNavigation"
import type { SliceWithProps } from "~/components/slices/SliceManager"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { hideFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

export type SliderPushClientProps = Omit<SliceWithProps<ComponentProps<"div">>, "children"> & {
  children: SliderProps["children"]
  shouldDisplayArrowButton: boolean
}

function SliderPushClient({ children, shouldDisplayArrowButton, isFirstSlice }: SliderPushClientProps) {
  const [{ maxSlideIndex, slideIndex, setSlideIndex, enableDrag, nextSlide, prevSlide }, setSliderState] =
    useSliderState()
  const t = useTranslate()
  const fixMaxSlideIndex = maxSlideIndex - 1

  return (
    <div
      data-comp="Slices/SliderPushClient"
      className={clsx(css.SliderPush, isFirstSlice ? css.firstSliceMargin : null)}
    >
      <Slider
        containerClassName={css.SliderContainer}
        setSliderState={setSliderState}
        snap
        maxSlideIndexChange={1}
        autoBlockSlideIndexChange
      >
        {children}
      </Slider>
      {shouldDisplayArrowButton ? (
        <>
          {slideIndex === 0 ? null : (
            <ArrowButton
              theme={"grey-delta/60"}
              className={clsx(css.ArrowButton["left"])}
              onClick={prevSlide}
              ariaLabel={t("aria_arrow_next")}
            />
          )}
          {slideIndex === fixMaxSlideIndex ? null : (
            <ArrowButton
              theme={"grey-delta/60"}
              className={clsx(css.ArrowButton["right"])}
              direction={"right"}
              onClick={nextSlide}
              ariaLabel={t("aria_arrow_prev")}
            />
          )}
        </>
      ) : null}
      <ClientSliderNavigation
        className={clsx(clsx(css.MobileSliderPushNavigation, hideFrom("tablet", "flex")))}
        maxSlideIndex={fixMaxSlideIndex}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        enableDrag={enableDrag}
        theme="white"
      />
    </div>
  )
}

export default SliderPushClient
